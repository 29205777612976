body {
  margin: 0;
  font-family: "Roboto" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto' !important;
}
.logo-images {
  max-height: 30px !important;
}

.logo_img {
  max-height: 28px !important;
}

.checkbox_days:first-child {
  margin-left: 0 !important;
}

.checkbox_days {
  margin-left: 10px;
  margin-right: 10px;
}
.icon_size{
  font-size: 26px !important;
}
.required_field{
  color: red;
}

@media (min-width: 320px) and (max-width: 749px) {
  .overview_admin {
    margin-top: 20px !important;
  }
}
@media (min-width: 750px) and (max-width: 820px){
}
@media (min-width: 900px) and (max-width: 912px){
}
@media (min-width: 913px) and (max-width: 1280px){
}
.field_off {
  background-color: red;
}
.field_off .title {
  color: #fff;
}
.field_on {
  background-color: rgb(68, 255, 68);
}
.field_on .title {
  color: black;
}
